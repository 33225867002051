<template>
  <div
    ref="container"
    class="map-container pa-0"
    @mousemove="positionInfoCard"
  >
    <GmapMap
      :center="map.center"
      :zoom="map.zoom"
      :options="map.options"
      class="map"
      ref="map"
      @zoom_changed="changedProp('zoom', $event)"
      @center_changed="changedProp('center', $event)"
      @drag="panned(true)"
    >
      <gmap-heatmap-layer
        v-if="map.zoom<map.impressions.minZoom"
        :data="heatmapData"
        :options="map.heatmap.options"
      />
      <!-- :options="{maxIntensity: 120, dissipating: false}" -->
      <GmapMarker
        v-else
        v-for="(p, i) in hourlyImpressions"
        :key="'impression-'+i"
        :position="p.position"
        :clickable="!disabled"
        :icon="map.icons.impression"
        @mouseover="impressionMouseOver(p, $event)"
        @mouseout="impressionMouseOut(p, $event)"
      />
      <gmap-cluster
        v-if="map.layers.buzzers"
        :zoom-on-click="true"
        :styles="map.cluster.styles"
        :max-zoom="map.cluster.maxZoom"
      >
        <GmapMarker
          v-for="m in trackedBuzzers"
          :key="m.code"
          :position="m.impression.position"
          :clickable="!disabled"
          :icon="setBuzzerIcon(m)"
          @click="selectBuzzer(m.code)"
        />
      </gmap-cluster>

      <gmap-cluster
        v-if="pois.toggle"
        :zoom-on-click="true"
        :styles="map.cluster.styles"
        :max-zoom="map.cluster.maxZoom"
      >
        <GmapMarker
          v-for="p in points"
          :key="'poi-'+p.id"
          :position="p.position"
          :opacity="metric==null ? p.opacity : p.opacity[metric]"
          :shape="p.shape"
          :clickable="!disabled"
          :icon="p.hasOwnProperty('icon') ? p.icon : map.icons.poi"
          @mouseover="poiMouseOver(p, $event)"
          @mouseout="poiMouseOut(p, $event)"
        />
      </gmap-cluster>

      <GmapMarker
        v-for="city in citiesMarkers"
        :key="'city-'+city.id"
        :position="city.position"
        :clickable="city.selectable"
        :icon="map.icons.city"
        @mouseover="cityMouseOver(city, $event)"
        @mouseout="cityMouseOut(city, $event)"
        @click="selectCity(city.id)"
      />
    </GmapMap>
    <slot 
      name="toolbar" 
    >
      <div 
        v-if="controls&&toolbar"
        class="map-toolbar d-flex"
      >
        <m-tabs
          :value="layer"
          :icon="icons.layers"
          :items="layers"
          outlined
          mandatory
          class="map-layer-selector"
          @change="onLayerChange"
        />
        <drawing-toolbar
          v-if="map.ready&&pois.drawable&&!disabled"
          :manager="map.pois.drawing.manager"
          :type="map.pois.drawing.type"
          :types="map.pois.drawing.types"
          class="ml-4"
          @draw="startDrawing"
          @cancel="clearDrawing"
        />
      </div>
    </slot>

    <v-card
      v-if="controls"
      elevation="1"
      class="map-controls d-flex flex-column"
    >
      <v-btn
        icon
        tile
        small
        class="grey--text text--darken-2"
        @click="map.zoom += 1"
      >
        <v-icon size="20">{{ icons.mdiPlus }}</v-icon>
      </v-btn>
      <v-btn
        tile
        icon
        small
        class="grey--text text--darken-2"
        @click="map.zoom -= 1"
      >
        <v-icon size="20">{{ icons.mdiMinus }}</v-icon>
      </v-btn>
    </v-card>

    <v-fade-transition>
      <div
        v-show="map.info.toggle||!!map.info.selected"
        ref="info"
        max-width="320px"
        :class="{ clickable: pois.editable&&!!map.info.selected }"
        class="info-window v-card rounded white"
        :style="{ top: map.info.top + 'px', left: map.info.left + 'px' }"
      >
        <v-card-title 
          v-if="map.info.media==null"
          class="title px-6 pt-3 grey--text text--darken-2 d-block text-truncate"
        >
          <h4 class="text-overline font-weight-black">{{ map.info.title }}</h4>
          <h5 v-show="map.info.city!=null" class="text-caption font-weight-medium text--disabled">{{ map.info.city }}</h5>
        </v-card-title>
        <v-card-text 
          v-if="metric!=null&&(geofences.toggle||pois.toggle)"
          class="pb-2 px-6"
        >
          <div 
            v-for="(info, m) in metrics"
            :key="`info-${m}`"
            class="text-body-2 font-weight-medium mb-4"
            :class="{ 'primary--text': metric==m }"
          >
            <v-icon 
              size="20"
              :color="metric==m ? 'primary' : 'grey'"
            >
              {{ icons[m] }}
            </v-icon>
            <b 
              class="ma-4 pt-1 font-weight-medium"
              :class="{ 'font-weight-black': metric==m }"
            >{{ info.value | numeral(m=='spent'||m=='cpm' ? '$ 0,0[.]0[0] a' : '0,0[.]0[0] a') }}</b>
          </div>
        </v-card-text>
        <media-player
          v-else-if="impressions.toggle&&map.info.media!=null"
          :url="map.info.media"
          :title="map.info.title"
          rounded
        />
        <div
          v-else-if="!!map.info.selected&&pois.toggle&&pois.editable"
          class="px-4 pb-4"
        >
          <v-btn
            text
            small
            @click="poiExpand(map.info.selected)"
          >
            Expandir +15m
          </v-btn>
        </div>
      </div>
    </v-fade-transition>

    <v-card
      v-if="map.layers.impressions||impressions.toggle"
      :disabled="!map.impressions.ready"
      min-width="360"
      min-height="56"
      elevation="1"
      class="impressions-hour-picker d-flex align-center pa-2"
    >
      <v-btn
        icon
        small
        :disabled="map.impressions.selected-1==0"
        color="grey darken-1"
        class="mr-2"
        @click="map.impressions.selected-=1"
      >
        <v-icon size="20">{{ icons.previous }}</v-icon>
      </v-btn>
      <v-btn
        icon
        small
        :color="map.impressions.playing ? 'primary' : 'grey darken-1'"
        class="mr-2"
        @click="toggleTimelapse"
      >
        <v-icon size="20">{{ map.impressions.playing ? icons.pause : icons.play }}</v-icon>
      </v-btn>
      <v-btn
        icon
        small
        :disabled="map.impressions.selected+1==map.impressions.hours.length"
        color="grey darken-1"
        class="mr-2"
        @click="map.impressions.selected+=1"
      >
        <v-icon size="20">{{ icons.next }}</v-icon>
      </v-btn>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="grey"
            outlined
            rounded
            x-small
            class="mx-2"
            v-on="on"
            v-bind="attrs"
          >
            <span class="text-lowercase">{{ map.impressions.speed.value }}x</span>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item
            v-for="(x, i) in map.impressions.speed.options"
            :key="'speed-'+i"
            @click="map.impressions.speed.value=x"
          >
            <v-list-item-title>{{ x }}x</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-slider
        v-if="map.impressions.ready"
        v-model="map.impressions.selected"
        min="0"
        :max="map.impressions.hours.length-1"
        ticks="always"
        :thumb-label="false"
        track-color="grey"
        hide-details
        color="primary"
        class="impressions-hour-slider ma-0"
      />
      <v-progress-linear
        v-else
        indeterminate
      />
      <span 
        v-show="map.impressions.ready"
        class="text-overline mx-2 primary--text font-weight-bold"
      >
        {{ map.impressions.selected | formatDate(map.impressions.hours, 'ddd, DD/MM HH') }}h
      </span>
    </v-card>

    <v-card
      v-if="metric!=null&&map.legend.min!=map.legend.max&&(map.layers.geofences||pois.toggle)"
      flat
      class="legend"
    >
      <!-- <v-card-title class="subtitle-2 grey--text">
        {{ map.legend.title }}
      </v-card-title> -->
      <v-card-text class="pa-2">
        <v-container 
          fluid 
          class="pa-0"
        >
          <v-row no-gutters>
            <v-col 
              align-self="center"
              class="text-left pr-2 text-no-wrap"
            >
              <span class="caption">
                {{ map.legend.min | numeral('0,0 a') }}
              </span>
            </v-col>
            <v-col
              v-for="(step, k) in map.legend.steps"
              :key="'tile-'+k"
              class="step"
              align-self="center"
            >
              <v-tooltip 
                top
              >
                <template v-slot:activator="{ on, attrs }">
                  <div
                    class="tile"
                    :style="calcLegendIntensity(step)"
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <span>{{ step | numeral('0,0 a') }}</span>
              </v-tooltip>
            </v-col>
            <v-col 
              align-self="center"
              class="pl-2 text-right text-no-wrap"
            >
              <span class="caption">
                {{ map.legend.max | numeral('0,0 a') }}
              </span>
            </v-col>
          </v-row>
          <!-- <v-row no-gutters>
            <v-col
              v-for="(step, k) in map.legend.steps"
              :key="'label-'+k"
              class="step text-center grey--text"
            >
              <span 
                v-if="k!=1&&k!=3"
                class="caption"
              >
                {{ step | numeral('0,0 a') }}
              </span>
            </v-col>
          </v-row> -->
        </v-container>
      </v-card-text>
      <!-- <v-slider
        v-model="map.filter.interval"
        inverse-label
        :max="map.filter.max"
        :min="map.filter.min"
        hide-details
        class="pa-2 pt-0 pb-0 pr-4 mb-n2"
        :prepend-icon="map.filter.player.controller == null ? icons.mdiPlay : icons.mdiStop"
        @click:prepend="playFilter"
        @click="map.filter.player.count = 10"
      /> -->
    </v-card>

    <v-menu
      :value="map.pois.drawing.cities.toggle"
      absolute
      attach=".map-container"
      :position-x="map.pois.drawing.cities.position.x"
      :position-y="map.pois.drawing.cities.position.y"
      :close-on-content-click="false"
      :close-on-click="false"
      offset-x
      right
    >
      <v-card>
        <v-list dense>
          <v-list-item
            v-for="(city, id) in map.pois.drawing.cities.options"
            :key="'poi-city-'+id"
            class="pr-6"
            @click="city.selected = !city.selected"
          >
            <v-list-item-icon
              class="mr-4 my-2"
            >
              <v-icon 
                dense
                :color="city.selected ? 'primary' : 'grey darken-1'"
              >
                {{ city.selected ? icons.checked : icons.unchecked }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                :class="[(city.selected ? 'primary--text' : 'grey--text  text--darken-1')]"
                class="text-subtitle-2"
              >
                {{ city.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider />
        <v-card-actions>
          <v-btn
            v-if="hasPoiCities"
            text
            small
            color="primary"
            @click="setPoiCities"
          >
            Adicionar
          </v-btn>
          <v-btn
            v-else
            text
            small
            color="error"
            @click="clearDrawing(map.pois.drawing.type)"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>


<style type="text/css">

  .map-container, .map {
    position: relative;
    width: 100%; 
    height: 100%;
    overflow: hidden;
  }
  @media (max-width: 600px) and (orientation: landscape) {
    .map-container .map {
      border-radius: 0;
    }
  }

  .map .vue-map > div {
    background: white !important;
  }

  .map-container {
    /* box-shadow: var(--inset-2); */
    /* border: thin solid rgba(0, 0, 0, 0.08) !important; */
  }

  .map-container .filter {
    position: absolute;
    top: 20px;
    left: 20px;
    opacity: .85;
  }

  .map-container .refresh-btn {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    z-index: 11;
    opacity: .85;
  }

  .map-container .info-window {
    position: fixed;
    top: 0;
    left: 0;
    opacity: .95;
    min-width: 180px;
    z-index: 5;
    pointer-events: none;
    filter: drop-shadow(0px 4px 8px rgba(61, 75, 143, 0.48));
  }
  .map-container .info-window.clickable {
    pointer-events: initial;
  }
  .map-container .info-window .title {
    letter-spacing: .05rem !important;
    line-height: 1.5rem;
  }

  .map-container .heatmap-btn {
    position: absolute;
    top: 1.5rem;
    left: 1.5rem;
    z-index: 11;
    opacity: .85;
  }

  .map-container .legend, .map-container .impressions-hour-picker {
    position: absolute;
    min-width: 240px;
    z-index: 3;
    opacity: .85;
  }
  .map-container .legend {
    top: 16px;
    right: 16px; 
  }
  .map-container .impressions-hour-picker {
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);
    width: 60%;
  }
  .map-container .legend .caption {
    display: inline-block;
    line-height: 1 !important;
  }
  .map-container .legend .tile {
    position: relative;
    height: 8px;
    border: 2px solid rgba(71, 108, 255, 0.08);
    margin: 0;
    will-change: border, box-shadow;
    transition: box-shadow .125s ease, border .125s ease;
    /* box-shadow: 0 0 0 0 #000; */
  }
  .map-container .legend .tile:hover {
    border: 2px solid rgba(71, 108, 255, 0.24);
    z-index: 200;
    /* box-shadow: 0 0 8px 6px #000; */
  }

  .gm-control-active img {
    width: 12px !important;
    height: 12px !important;
  }

  .map-container .map-controls {
    position: absolute;
    bottom: 24px;
    right: 16px;
  }
  .map-container .map-toolbar {
    position: absolute;
    top: 16px;
    left: 16px;
  }

  .map-container .cluster span {
    display: inline-block;
    padding-top: 14px;
    padding-left: 4px;
  }

</style>

<script>
  // Icons
  import { mdiAccount, mdiClock, mdiCurrencyUsd, mdiImage, mdiMagnifyPlusOutline, mdiPlus, mdiMinus, mdiPlay, mdiPause, mdiSkipNext, mdiSkipPrevious, mdiCamera, mdiLayers, mdiCheck, mdiCheckboxMarked, mdiCheckboxBlankOutline } from '@mdi/js'

  // Utilities
  import services, { icons } from '@/services'
  import { sync } from 'vuex-pathify'
  import maps from '@/maps.js'
  import { getGoogleMapsAPI as gmapApi } from 'gmap-vue'
  import _ from 'lodash';
  import device from 'mobile-device-detect'
  import { coordEach, getCoord, center, feature, intersect, circle, buffer } from '@turf/turf'
  var moment = require('moment');

  export default {
    name: 'MapComp',

    props: {
      heatmap: {
        type: Object,
        default: () => {
          return {
            toggle: false,
            data: [],
            options: {},
          }
        }
      },
      buzzers: {
        type: Object,
        default: () => {
          return {
            toggle: false,
            data: [],
            selected: null,
            alert: false,
            options: {},
          }
        }
      },
      geofences: {
        type: Object,
        default: () => {
          return {
            toggle: false,
            data: {},
            options: {},
          }
        }
      },
      pois: {
        type: Object,
        default: () => {
          return {
            toggle: false,
            data: [],
            drawable: false,
            options: {},
          }
        }
      },
      outline: {
        type: Object,
        default: () => {
          return {
            toggle: false,
            data: [],
            style: {},
          }
        }
      },
      impressions: {
        type: Object,
        default: () => {
          return {
            toggle: false,
            data: {},
            options: {},
          }
        }
      },
      
      options: {
        type: Object,
        default: () => {}
      },
      cities: {
        type: Object,
        default: () => {}
      },
      metric: {
        type: String,
        default: null,
      },
      layers: {
        type: Array,
        default: () => ['buzzers']
      },
      layer: {
        type: String,
        default: () => 'geofences'
      },
      layerSelection: {
        type: Boolean,
        default: true
      },
      roles: {
        type: Array,
        default: () => [],
      },
      legend: {
        type: Boolean,
        default: true
      },
      controls: {
        type: Boolean,
        default: true
      },
      toolbar: {
        type: Boolean,
        default: true,
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },

    data: () => ({
      icons: {
        mdiMagnifyPlusOutline,
        mdiPlus,
        mdiMinus,
        audience: mdiAccount,
        impressions: mdiImage,
        spent: mdiCurrencyUsd,
        cpm: icons.cpm,
        airtime: mdiClock,
        gallery: mdiCamera,
        play: mdiPlay,
        pause: mdiPause,
        previous: mdiSkipPrevious,
        next: mdiSkipNext,
        layers: mdiLayers,
        check: mdiCheck,
        checked: mdiCheckboxMarked,
        unchecked: mdiCheckboxBlankOutline,
      },
      device: device.isMobileOnly,
      map: {
        ref: null,
        ready: false,
        heatmap: {
          loaded: false,
          options: {
            radius: 24,
            opacity: .64,
            gradient: [
              'rgba(105, 141, 242, 0)', 
              'rgba(105, 141, 242, 0.2)', 
              'rgba(105, 141, 242, 0.4)', 
              'rgba(105, 141, 242, 0.64)', 
              'rgba(105, 141, 242, 0.8)', 
              // 'rgba(105, 141, 242, 0.8)',
              // 'rgba(255, 71, 102, 0.48)',
              'rgba(255, 71, 102, 0.8)',
              'rgba(255, 71, 102, 1)',
            ],
          },
          ref: null,
        },
        layers: {
          heatmap: false,
          buzzers: false,
          geofences: false,
          outline: false,
          pois: false,
          impressions: false
        },
        cities: {
          loaded: false,
          source: {}
        },
        geofences: {
          data: {},
          type: 'geofences',
          loader: {
            pending: {},
            loading: false,
            map: null
          },
          source: {},
          features: {},
          display: [],
          ref: null,
          events: {
            over: null,
            out: null,
            click: null
          },
        },
        outline: {
          data: {},
          loader: {
            pending: {},
            loading: false,
            map: null
          },
          available: {},
          source: {},
          features: {},
          display: [],
          ref: null,
          events: {
            over: null,
            out: null,
            click: null
          },
        },
        pois: {
          data: {},
          loader: {
            pending: {},
            loading: false
          },
          source: {},
          features: {},
          points: {},
          drawing: {
            type: null,
            types: [
              {
                value: 'polygon', 
                text: 'Polígono',
              },
              {
                value: 'marker', 
                text: 'Ponto de Interesse',
              }
            ],
            manager: null,
            cities: {
              toggle: false,
              poi: null,
              options: {},
              position: { x: 0, y: 0 },
              pending: []
            }
          },
          style: {
            fillColor: '#698DF2',
            fillOpacity: .24,
            strokeColor: '#698DF2',
            strokeOpacity: .64,
            strokeWeight: 1,
          },
          ref: null,
          events: {
            over: null,
            out: null,
            click: null
          },
          bounded: false
        },
        impressions: {
          selected: null,
          hours: [],
          ready: false,
          playing: false,
          timer: null,
          speed: {
            value: 1,
            options: [0.5, 1, 1.5]
          },
          minZoom: 15,
          ref: null,
          events: {
            over: null,
            out: null,
            click: null
          },
        },
        info: {
          toggle: false,
          top: 0,
          left: 0,
          title: '',
          media: null,
          selected: null,
          metrics: {
            audience: {
              value: null,
            },
            impressions: {
              value: null,
            },
            spent: {
              value: null,
              roles: [1,5,6,7]
            },
            cpm: {
              value: null,
              roles: [1,5,6,7]
            },
            airtime: {
              value: null,
            },
          }
        },
        legend: {
          title: 'Mapa de Impactos',
          steps: [],
          range: 5,
          min: 0,
          max: 0,
        },
        radius: 30,
        center: {
          lat:-22.9548645, lng:-43.189667
          // lat: 37, lng: -122
        },
        zoom: 12,
        options: {
          ...maps.light,
          padding: {
            top: 36,
            left: 36,
            right: 36,
            bottom: 36
          },
          maxZoom: 16,
          disableDefaultUI: true
        },
        icons: {
          poi: {
            url: "/img/poi-marker.svg",
            size: { width: 24, height: 24, f: 'px', b: 'px' },
            anchor: { x: 12, y: 16 }
          },
          city: {
            url: "/img/city-marker.svg",
            size: { width: 40, height: 40, f: 'px', b: 'px' },
            anchor: { x: 20, y: 20 }
          },
          buzzer: {
            url: "/img/buzzer-marker.svg",
            size: { width: 32, height: 32, f: 'px', b: 'px' },
            anchor: { x: 16, y: 16 }
          },
          impression: {
            url: "/img/impression-marker.svg",
            size: { width: 32, height: 32, f: 'px', b: 'px' },
            anchor: { x: 16, y: 16 }
          },
        },
        cluster: {
          styles: [
            {
              textColor: 'white',
              url: '/img/poi-cluster.png',
              height: 40,
              width: 40
            },
          ],
          maxZoom: 12
        },
        btn: {
          disabled: false,
          loading: false
        },
        controls: {
          zoom: false
        }
      },
    }),

    computed: {
      views: sync('app/views'),
      loading: sync('app/views@loading'),
      view: sync('app/views@map'),
      user: sync('user/data'),
      toast: sync('app/toast'),

      google: gmapApi,

      // heatmapMax () {
      //   const filter = this.map.filter;
      //   const data = this.heatmap.data[_.find(filter.steps, { doy: filter.interval }).date];

      //   const maxPerDay = _.map(data, (group) => {
      //     return _.reduce(group, (max, point) => {
      //       return point.audience + max;
      //     },0);
      //   }).sort()[0];
      //   const max = _.reduce(data, (max, point) => {
      //     return point.audience > max ? point.audience : max;
      //   },0);
      //   const count = this.map.data.serve.length;
      //   const total = _.reduce(this.map.data.serve, (total, point) => {
      //     return point.audience + total;
      //   },0);
      //   const avg = total / count;
      //   // console.log(max, maxPerDay, avg);
      //   return avg;
      // },

      metrics () {
        const roles = this.user.roles;
        return _.pickBy(this.map.info.metrics, d => !_.has(d, 'roles') || _.size(_.intersection(d.roles, roles))>0);
      },

      points () {
        return _.values(this.map.pois.points);
      },

      hasPoiCities () {
        const cities = _.clone(this.map.pois.drawing.cities.options);
        const selected = _.filter(cities, ['selected', true]);
        return _.size(selected)>0;
      },

      filterRangeText () {
        const steps = this.map.filter.steps;
        return this.map.filter.steps.length > 0 ? moment(_.find(steps, { 'doy': this.map.filter.interval }).date, 'DD/MM/YY').format('DD/MM') : '';
      },

      trackedBuzzers () {
        return _.pickBy(this.buzzers.data, (item) => {
          return item.impression.position.lat !== 0 && item.impression.position.lat !== null;
        });
      },

      citiesMarkers () {
        const source = _.clone(this.map.cities.source);
        const cities = _.pickBy(_.clone(this.cities), city => _.has(city, 'marker') && city.marker && _.has(source, city.id) && _.has(source[city.id], 'center'));
        console.log(cities);
        return _.map(cities, (city) => {
          return {
            id: city.id,
            title: city.title,
            position: source[city.id].center,
            selectable: _.has(city, 'selectable') && city.selectable
          }
        })
      },

      hourlyImpressions () {
        const controller = this.map.impressions;
        const data = _.isNil(controller.selected) ? [] : this.impressions.data[controller.hours[controller.selected]];
        // console.log('timestamp mapped', data);
        return data;
      },
      heatmapData () {
        const weight = 1; //_.size(this.hourlyImpressions);
        const data = this.google ? _.map(this.hourlyImpressions, i => {
          return { 
            location: new this.google.maps.LatLng(i.position),
            weight
          }
        }) : [];
        return data;
      },

      auditor () {
        return _.some(this.user.roles, ['id_perfil', 9]);
      }
    },

    watch: {
      pois: {
        immediate: true,
        deep: true,
        handler (set) {
          // console.log('POI', get);
          if (set.toggle) {
            this.$nextTick(() => {
              if (this.map.ready&&!_.isEmpty(set.data)) {
                this.initPoiLayer();
              }else{
                this.clearLayer('pois');
              }
            });
          }else{
            // clear layer data
            if (_.size(this.map.pois.features)>0||_.size(this.map.pois.points)>0) this.clearLayer('pois');
          }
          this.map.layers.pois = set.toggle;
        }
      },
      outline: {
        immediate: true,
        deep: true,
        handler (set) {
          // console.log(get);
          if (set.toggle) {
            this.$nextTick(() => {
              if (this.map.ready&&!_.isEmpty(set.data)) {
                this.initOutlineLayer();
              }
            });
          }else{
            // clear layer data
            if (_.size(this.map.outline.features)>0) this.clearLayer('outline');
          }
          this.map.layers.outline = set.toggle;
        }
      },
      geofences: {
        immediate: true,
        deep: true,
        handler (set) {
          // console.log(get);
          if (set.toggle&&!_.isEmpty(set.data)) {
            this.$nextTick(() => {
              if (this.map.ready) {
                this.initGeofenceLayer();
              }
            });
          }else{
            // clear layer data
            if (_.size(this.map.geofences.features)>0) this.clearLayer('geofences');
          }
          this.map.layers.geofences = set.toggle;
        }
      },
      metric: {
        handler (m) {
          // console.log(m);
          this.$nextTick(() => {
            if (this.map.layers.geofences&&this.map.ready) {
              this.initGeofenceLayer();
            }else if (this.map.layers.pois&&this.map.ready) {
              this.initPoiLayer();
            }
          });
        }
      },
      buzzers: {
        immediate: true,
        deep: true,
        handler (get) {
          // console.log(get);
          if (get.toggle) {
            if (get.selected!=null) {
              // console.log(get.selected);
              this.getBounds();
            }
          }else{
          }
          this.map.layers.buzzers = get.toggle;
        }
      },
      impressions: {
        immediate: true,
        deep: true,
        handler (get) {
          // console.log('Map Impressions', get);
          if (get.toggle) {
            if (!_.isEmpty(get.data)) {
              // console.log(get.selected);
              const controller = this.map.impressions;
              const hours = _.keys(get.data).sort();
              if (controller.playing) {
                const hour = controller.hours[controller.selected];
                controller.selected = _.indexOf(hours, hour);
              }else if (controller.selected==null) {
                controller.selected = 0;
                this.toggleTimelapse(true);
              }
              controller.hours = hours;
              this.getBounds();
            }
          }else{
            this.clearLayer('impressions');
          }
          this.map.layers.impressions = get.toggle;
        }
      },
      // heatmap: {
      //   immediate: true,
      //   deep: true,
      //   handler (get) {
      //     // console.log(get);
      //     if (get.toggle) {
      //       this.$refs.map.$mapPromise.then((map) => {
      //         if (this.map.heatmap.ref!=null) {
      //           this.map.heatmap.ref.then(heatmap => heatmap.setMap(map));
      //           this.getBounds();
      //         }else{
      //           this.map.heatmap.ref = this.initHeatmap();
      //         }
      //       });
      //     }else{
      //       // this.map.heatmap.ref.then(heatmap => heatmap.setMap(null));
      //     }
      //     this.map.layers.heatmap = get.toggle;
      //   }
      // },

      cities: {
        immediate: true,
        deep: true,
        handler (cities) {
          if (this.map.ready&&!_.isEmpty(cities)) {
            this.loadCities(cities);
          }
        }
      },

      options: {
        immediate: true,
        deep: true,
        handler (options) {
          if (_.has(options, 'padding')&&_.isNumber(options.padding)) {
            options.padding = { 
              top: options.padding,
              left: options.padding,
              right: options.padding,
              bottom: options.padding,
            }
          }
          this.map.options = _.merge(this.map.options, _.clone(options));
        }
      },

      'map.geofences.loader.pending': {
        handler (pending) {
          if (!_.isEmpty(pending)) { 
            if (!this.map.geofences.loader.loading) {
              this.geojsonLoader('geofences', this.map.geofences.ref);
            }
          }else if (!_.isEmpty(this.map.geofences.features)) {
            console.log('get geofences bounds');
            this.mapBounds('geofences');
          }
        }
      },
      'map.outline.loader.pending': {
        handler (pending) {
          if (!_.isEmpty(pending)) { 
            if (!this.map.outline.loader.loading) {
              // this.geojsonLoader('outline', this.map.outline.ref);
            }
          }else if (!_.isEmpty(this.map.outline.features)) {
            console.log('get outline bounds');
            this.mapBounds('outline');
          }
        }
      },
      'map.pois.loader.pending': {
        handler (pending) {
          if (!_.isEmpty(pending)) { 
            if (!this.map.pois.loader.loading) {
              this.geojsonLoader('pois', this.map.outline.ref);
            }
          }else if (!_.isEmpty(this.map.pois.features)) {
            console.log('get geofences bounds');
            if (!this.map.pois.bounded) this.mapBounds('pois');
          }
        }
      },

      // $route (to, from) {
      //   if (this.map.ready) this.updateView(to);
      // }
    },

    filters: {
      formatDate (selected, hours, format) {
        const date = hours[selected];
        return moment(date).format(format)+'–'+moment(date).add(1,'H').format('HH');
      }
    },

    methods: {
      ...services,

      toggleTimelapse (b) {
        const controller = this.map.impressions;
        b = _.isNil(b)||!_.isBoolean(b) ? !controller.playing : b;
        console.log('toggleTimelapse', b);
        if (b) {
          if (!_.isNil(controller.timer)) clearTimeout(controller.timer);
          controller.timer = setTimeout(($) => {
            $.updateTimelapse();
          }, 1000/controller.speed.value, this);
          controller.ready = true;
          controller.playing = true;
        }else{
          controller.timer = clearTimeout(controller.timer);
          controller.playing = false;
        }
      },

      updateTimelapse () {
        const controller = this.map.impressions;
        const max = controller.hours.length-1;
        controller.selected = controller.selected+1>max ? 0 : controller.selected+1;
        if (controller.playing) {
          console.log('updateTimelapse', controller.selected);
          controller.timer = setTimeout(($) => {
            $.updateTimelapse();
          }, 1000/controller.speed.value, this);
        }
      },

      impressionMouseOver (point, event) {
        if (point.media!=null) {
          // console.log(point.lat, point.lng);
          this.map.info.title = point.title;
          this.map.info.media = point.media;
          this.map.info.toggle = true;
        }
      },
      impressionMouseOut (point, event) {
        this.map.info.toggle = false;
      },

      onLayerChange (layer) {
        this.$emit('layer-change', layer);
      },

      changedProp (prop, e) {
        if (prop=='zoom') {
          this.map.info.toggle = false;
          this.map[prop] = e;
        }
      },

      panned (b) {
        this.view.panned = b;
      },

      setLegend () {
        let legend =  this.map.legend;
        legend.steps = _.concat([legend.min], _.map([...Array(legend.range-2).keys()], (r) => {
          return _.round((legend.max / (legend.range+2)) * (r+1));
        }), [legend.max]);
      },

      startDrawing (type) {
        const $ = this;
        if ($.map.pois.drawing.type!=null&&!_.isNil($.map.pois.drawing.manager)) {
          $.clearDrawing()
        }
        $.map.pois.drawing.type = type;
        if (_.isNil($.map.pois.drawing.manager)) {
          $.map.pois.drawing.manager = new $.google.maps.drawing.DrawingManager({
            drawingMode: type,
            drawingControl: false,
            polygonOptions: {
              ...$.map.pois.style,
              strokeWeight: 3,
              strokeOpacity: .8,
              draggable: false,
              editable: false,
              clickable: true,
              zIndex: 100,
            },
            markerOptions: {
              icon: "/img/poi-marker.svg",
              draggable: false,
              editable: false,
              clickable: true,
              zIndex: 100,
            },
          });
        }else{
          $.map.pois.drawing.manager.setOptions({
            drawingMode: type,
          });
        }

        $.map.pois.drawing.manager.setMap($.map.ref);

        $.google.maps.event.addListener($.map.pois.drawing.manager, 'overlaycomplete', function(event) {
          
          let poi = {
            id: Date.now(),
            title: '',
            type: 'CUS',
            use: 'REG',
            local_id: null,
            city: {
              id: null,
              title: null,
            },
          }
          if (type=='polygon') {
            let geometry = []
            const path = event.overlay.getPath();
            _.each([...Array(path.length).keys()], (i) => {
              const lat = path.getAt(i).lat();
              const lng = path.getAt(i).lng();
              geometry.push([lng, lat])
            });
            geometry.push(geometry[0]);
            poi['geojson'] = JSON.stringify({
              type: _.upperFirst(event.type),
              coordinates: [geometry]
            });
            poi.type = 'CUS';
            poi.title = 'Polígono '+(_.size($.pois.data)+1)
          }else if (type=='marker') {
            poi.position = {
              lat: event.overlay.position.lat(),
              lng: event.overlay.position.lng(),
            }
            poi.type = 'POI';
            poi.radius = 1000;
            poi.title = 'POI '+(_.size($.pois.data)+1)
          }

          setTimeout(($, poi) => {
            $.processPoi(poi)
          }, 250, $, poi);
        })
      },

      clearDrawing (type=null) {
        const controller = this.map.pois.drawing;
        controller.type = type;
        if (controller.manager!=null&&type==null) {
          controller.manager.setMap(null);
          controller.manager = null;
        }
        controller.cities.toggle = false;
        controller.cities.options = {};
        controller.cities.poi = null;
      },

      checkPoiCities (poi) {
        // verify if POI is inside suported areas
        console.log('checkPoiCities', poi);
        let geometry;
        if (poi.type=='POI') {
          geometry = circle([poi.position.lng, poi.position.lat], poi.radius/1000);
        }else{
          geometry = feature(JSON.parse(poi.geojson));
        }
        const cities = _.pickBy(_.clone(this.cities), city => {
          const perimeter = this.map.cities.source[city.id];
          const intersection = _.some(perimeter.features, f => intersect(f, geometry) != null);
          return intersection;
        });
        return _.mapValues(cities, (city, cid) => {
          const { id, title } = this.cities[cid];
          return { id, title }
        });
      },

      setPoiCities () {
        const cities = _.filter(this.map.pois.drawing.cities.options, ['selected', true]);
        const poi = this.map.pois.drawing.cities.poi;
        const pois = _.map(cities, (city, i) => {
          return {
            ..._.clone(poi),
            id: poi.id+i,
            title: poi.title + ' (' + city.title + ')',
            city,
            local_id: city.id
          }
        })
        const data = _.isNil(this.pois.data)||_.isEmpty(this.pois.data) ? pois : _.concat(_.clone(this.pois.data), pois);
        
        this.$emit('update', { prop: 'pois', value: data });

        this.clearDrawing(this.map.pois.drawing.type);
        this.map.pois.drawing.cities.pending = _.reject(this.map.pois.drawing.cities.pending, ['id', poi.id]);
        if (this.map.pois.drawing.cities.pending.length>0) this.processPoi(_.first(this.map.pois.drawing.cities.pending));
      },

      processPoi (poi) {
        const $ = this;
        const cities = $.checkPoiCities(poi);
        if (_.size(cities)==0) {  
          $.$emit('message', 'ATENÇÃO: O hotspot está fora '+(_.size($.cities)>1 ? 'das cidades selecionadas.' : 'da cidade selecionada.'));
        }else if (_.size(cities)==1) {
          const city = _.values(cities)[0];
          poi = { ...poi, city, local_id: city.id }
          const data = _.isNil($.pois.data)||_.isEmpty($.pois.data) ? [poi] : _.concat(_.clone($.pois.data), [poi]);
          $.clearDrawing($.map.pois.drawing.type);
          $.$emit('update', { prop: 'pois', value: data });
          if (!$.cities[city.id].selected) $.selectCity(city.id);
        }else{
          // $.map.pois.drawing.cities.pending.push(poi);
          if ($.map.pois.ref==null) {
            $.map.pois.ref = new $.google.maps.Data({ map: $.map.ref });
          }
          $.map.pois.drawing.cities.poi = poi;
          $.map.pois.drawing.cities.options = _.mapValues(cities, city => {
            return { ...city, selected: true };
          });
          $.map.pois.drawing.cities.toggle = true;
          if (!_.has($.map.pois.data, poi.id)) {
            if (poi.type=='POI') {
              const drawing = new $.google.maps.Circle({
                ...$.map.pois.style,
                map: $.map.ref,
                center: poi.position,
                radius: poi.radius,
              });
              $.$set($.map.pois.points, poi.id, {
                drawing,
                opacity: .8,
                ...poi
              });
            }else{
              const feature = $.map.pois.ref.addGeoJson($.formatGeojson(poi.geojson));
              $.$set($.map.pois.features, poi.id, feature);
            }
          }
          $.mapBounds('pois', [poi.id], null, () => {
            setTimeout(($, poi) => {
              let position;
              if (poi.type=='POI') {
                position = poi.position;
              }else{
                const [lng, lat] = getCoord(center($.formatGeojson(poi.geojson)))
                position = { lat, lng }
              }
              position =  new $.google.maps.LatLng(position.lat, position.lng);
              const overlay = new $.google.maps.OverlayView();
              overlay.setMap($.map.ref)
              const projection = overlay.getProjection();
              const { x, y } = projection.fromLatLngToContainerPixel(position);
              console.log('event', x, y);
              $.map.pois.drawing.cities.position = { x, y };
            }, 250, $, poi);
          });
        }
      },

      poiExpand (poi, offset=15) {
        const $ = this;
        let data = _.cloneDeep($.pois.data);
        const i = _.findIndex(data, ['id', poi.id]);

        let geojson = _.clone($.map.pois.source[poi.id]);
        geojson = JSON.stringify(buffer(geojson, offset/1000));
        data[i] = { ..._.clone(poi), geojson };
        this.$delete($.map.pois.data, poi.id);
        this.$delete($.map.pois.source, poi.id);
        $.map.pois.features[poi.id].forEach(f => $.map.pois.ref.remove(f));
        this.$delete($.map.pois.features, poi.id);
        $.map.info.selected = null;
        console.log(poi, geojson);
        $.$emit('update', { prop: 'pois', value: data });
      },

      async loadCities (cities) {
        const $ = this;
        cities = _.isNil(cities) ? _.clone($.cities) : cities;
        for await (const city of _.values(cities)) {
          if (!_.has($.map.cities.source, city.id)) {
            let ne, sw;
            $.$set($.map.cities.source, city.id, await fetch(city.url).then(r => r.json()).then((json) => {
                const geojson = _.has(json, 'geojson') ? JSON.parse(json.geojson) : json;
                let bounds = new $.google.maps.LatLngBounds();
                if (_.has(geojson, 'properties')&&_.has(geojson.properties, 'ne')) {
                  ne = geojson.properties.ne;
                  bounds.extend({ lng: ne[1], lat: ne[0] });
                  sw = geojson.properties.sw;
                  bounds.extend({ lng: sw[1], lat: sw[0] });
                  // console.log(ne, sw);
                }else{
                  coordEach(geojson, position => {
                    bounds.extend({ lng: position[0], lat: position[1] });
                  })
                  ne = [bounds.getNorthEast().lat(), bounds.getNorthEast().lng()]
                  sw = [bounds.getSouthWest().lat(), bounds.getSouthWest().lng()]
                }
                const center = { lat: ((ne[0] - sw[0])/2)+sw[0], lng: ((ne[1] - sw[1])/2)+sw[1] }
                // console.log(ne, sw, center); 
                geojson.center = center;
                geojson.bounds = bounds;
                $.$emit('bounds', bounds);
                return geojson;
              })
              .catch((error) => {
                console.log(error);
              }))
          }
        }
        console.log('cities loaded'); 
        $.map.cities.loaded = true;
      },

      async initPoiLayer () {
        console.log('initPoiLayer...');
        const $ = this;
        const data = this.pois.data;
        const selectable = this.pois.selectable;

        if (!_.isNil($.metric)) {
          const metric = $.metric;
          const s = _.toArray(data);
          _.each(['audience', 'impressions', 'spent', 'cpm', 'airtime'], m => {
            if (_.some(data, (d) => _.has(d, m))) {
              const l = {
                min: _.minBy(s, m)[m],
                max: _.maxBy(s, m)[m],
              }
              $.map.legend[m] = l;
            }
          });
          $.map.legend.min = $.map.legend[metric].min;
          $.map.legend.max = $.map.legend[metric].max;
        }

        return $.$refs.map.$mapPromise.then((map) => {
          if ($.map.ref==null) $.map.ref = map;
          if ($.map.pois.ref==null) {
            $.map.pois.ref = new $.google.maps.Data({ map });
          }
          $.map.pois.ref.setStyle($.setPoiStyle);

          if ($.pois.point) {
            // POI Radius Drawing
            const pois = _.keyBy($.pois.data, 'id');
            const features = $.map.pois.data;
            const updated = _.every(pois, p => _.has(features, p.id) && ((_.has(p, 'radius') ? _.isEqual(p.radius, features[p.id].radius) : true)&&_.isEqual(p.title, features[p.id].title)))&&_.size(pois)==_.size(features);
            if (updated) {
              if (_.has($.pois, 'zoom')&&!_.isEmpty($.pois.zoom)) $.mapBounds('pois', $.pois.zoom);
              return;
            }
            $.clearLayer('pois', false, false);
            $.map.pois.data = _.clone(pois);

            $.google.maps.event.removeListener($.map.pois.events.over);
            $.map.pois.events.over = $.map.pois.ref.addListener("mouseover", (event) => {
              $.map.pois.ref.revertStyle();
              $.map.pois.ref.overrideStyle(event.feature, { 
                strokeWeight: 2,
                // fillOpacity: .4,
                strokeOpacity: .64,
              });
              const id = event.feature.getProperty('id');
              if (_.isNil(id)||!_.has($.map.pois.data, id)) return;
              const poi = $.map.pois.data[id];
              // console.log('poi', id, poi);
              $.map.info.title = poi['title'];
              $.map.info.city = poi['city']['title'];
              if (!_.isNil($.metric)) {
                $.map.info.metrics.audience.value = poi['audience'];
                $.map.info.metrics.impressions.value = poi['impressions'];
                $.map.info.metrics.spent.value = poi['spent'];
                $.map.info.metrics.cpm.value = poi['cpm'];
                $.map.info.metrics.airtime.value = poi['airtime'];
              }
              $.map.info.toggle = true;
            });

            $.google.maps.event.removeListener($.map.pois.events.out);
            $.map.pois.events.out = $.map.pois.ref.addListener("mouseout", (event) => {
              $.map.pois.ref.revertStyle();
              $.map.info.toggle = false;
            });

            if (selectable) {
              $.google.maps.event.removeListener($.map.pois.events.click);
              $.map.pois.events.click = $.map.pois.ref.addListener("mouseup", (event) => {
                $.map.pois.ref.revertStyle();
                $.map.pois.ref.overrideStyle(event.feature, { 
                  strokeWeight: 2,
                  // fillOpacity: .4,
                  strokeOpacity: .64,
                });
                const id = event.feature.getProperty('id');
                if (_.isNil(id)||!_.has($.map.pois.data, id)) return;
                const poi = $.map.pois.data[id];
                poi.selected = !poi.selected;
                $.map.info.selected = poi.selected ? poi : null;
                console.log('poi', id, poi);
              });
            }

            const unknownCity = _.filter(pois, poi => {
              return _.isNil(poi.city);
            })

            const points = _.filter(pois, p => p.type=='POI'&&!_.isNil(p.city));
            const style = _.has(data, 'style') ? style : $.map.pois.style;
            _.each(points, (p, i) => {
              if (_.has(p, 'position')) {
                p.id = _.has(p, 'id') ? p.id : i;
                const drawing = new $.google.maps.Circle({
                  ...style,
                  map,
                  center: p.position,
                  radius: _.has(p, 'radius') ? p.radius : 1000,
                });
                $.$set($.map.pois.points, p.id, {
                  drawing,
                  selected: false,
                  opacity: _.isNil($.metric) ? .8 : _.mapValues({'audience': null, 'impressions': null, 'spent': null, 'cpm': null, 'airtime': null}, (v,m) => {
                    return $.setPointStyle(p[m], m, style).fillOpacity;
                  }),
                  ...p
                });
              }
            });

            const polygons = _.filter(pois, p => p.type=='CUS'&&!_.isNil(p.city));
            let pending = {};
            _.each(polygons, (g, k) => {
              if (_.has(g, 'geojson')&&!_.isNil(g.geojson)) {
                $.addGeojson('pois', $.formatGeojson(g.geojson), g.id, g, $.map.pois.ref);
              }else if (_.has($.map.pois.source, g.id)) {
                $.addGeojson('pois', $.map.pois.source[g.id], g.id, g, $.map.pois.ref);
              }else{
                pending[g.id] = g;
              }
            });

            if (!_.isEmpty(pending)) {
              $.map.pois.loader.pending = Object.assign({}, pending);
            }else{
              if (_.size(unknownCity)==0) {
                console.log('pois bounds');
                // $.mapBounds('pois');
              }else{
                $.map.pois.drawing.cities.pending = _.clone(unknownCity);
                $.processPoi(_.first($.map.pois.drawing.cities.pending));
              }
            }
            
            $.setLegend();
          }else{
            // POI Geojson
            const geometries = _.keyBy($.pois.data, 'id');
            $.map.pois.data = _.clone(geometries);
            const points = $.map.pois.points;
            const features = $.map.pois.features;
            const updated = _.difference(_.keys(geometries), _.keys(features)).length>0 || _.size(geometries)!=_.size(features) || _.size(points)>0;
            if (!updated) {
              if (_.has($.pois, 'zoom')&&!_.isEmpty($.pois.zoom)) $.mapBounds('pois', $.pois.zoom);
              return;
            }

            $.clearLayer('pois', false, false);

            $.google.maps.event.removeListener($.map.pois.events.over);
            $.map.pois.events.over = $.map.pois.ref.addListener("mouseover", (event) => {
              $.map.pois.ref.revertStyle();
              $.map.pois.ref.overrideStyle(event.feature, { 
                strokeWeight: 2,
                // fillOpacity: .4,
                strokeOpacity: .64,
              });
              const id = event.feature.getProperty('id');
              const poi = $.map.pois.data[id];
              console.log('poi', id, poi);
              $.map.info.title = poi['title'];
              $.map.info.city = poi['city']['title'];
              if (!_.isNil($.metric)) {
                $.map.info.metrics.audience.value = poi['audience'];
                $.map.info.metrics.impressions.value = poi['impressions'];
                $.map.info.metrics.spent.value = poi['spent'];
                $.map.info.metrics.cpm.value = poi['cpm'];
                $.map.info.metrics.airtime.value = poi['airtime'];
              }
              $.map.info.toggle = true;
            });

            $.google.maps.event.removeListener($.map.pois.events.out);
            $.map.pois.events.out = $.map.pois.ref.addListener("mouseout", (event) => {
              $.map.pois.ref.revertStyle();
              $.map.info.toggle = false;
            });

            let pending = {};
            console.log(geometries);

            let i=0;
            _.each(geometries, (g, k) => {
              if (_.has(g, 'geojson')||_.has($.map.pois.source, g.id)) {
                const geometry = _.has(g, 'geojson')&&!_.isNil(g.geojson) ? $.formatGeojson(g.geojson) : $.map.pois.source[g.id];
                $.addGeojson('pois', geometry, g.id, g, $.map.pois.ref);
              }else{
                pending[g.id] = g;
              }
            });

            if (!_.isEmpty(pending)) {
              $.map.pois.loader.pending = Object.assign({}, pending);
            }else{
              console.log('pois bounds');
              $.mapBounds('pois');
            }

            $.setLegend();
          }
        });
      },

      setPoiStyle (feature) {
        const style = _.has(this.pois, 'style') ? this.pois.style : {
          fillColor: '#698DF2',
          strokeColor: '#698DF2',
          strokeOpacity: .24,
          strokeWeight: 1,
        };

        const min = this.map.legend.min;
        const max = this.map.legend.max;
        const id = feature.getProperty('id');
        let fraction = 0;
        if (_.has(this.map.pois.data, id)&&!_.isNil(this.metric)) {
          const metric = this.map.pois.data[id][this.metric];
          fraction = (metric / max);
        }

        // console.log(color, fraction);

        return {
          fillColor: style.fillColor, //color
          fillOpacity: (fraction * .48) + .32,
          strokeColor: style.strokeColor,
          strokeOpacity: style.strokeOpacity,
          strokeWeight: style.strokeWeight,
          zIndex: parseInt(id)
        }
      },

      clearLayer (layer, data, style) {
        console.log('clear map layer', layer);
        layer = _.isNil(layer) ? 'all' : layer;
        if (layer=='all'||layer=='pois') {
          let points = this.map.pois.points;
          if (_.size(points)>0) {
            _.each(points, d => {
              d.drawing.setMap(null);
            });
            this.map.pois.points = {};
          }
          if (!_.isNil(this.map.pois.ref)) {
            _.each(this.map.pois.features, feature => {
              feature.forEach(f => this.map.pois.ref.remove(f));
            });
            _.each(this.map.pois.events, e => {
              this.google.maps.event.removeListener(e);
            });
            if (style!==false) {
              console.log('clear style');
              this.map.pois.ref.setStyle({});
            }
          }
        }
        if (layer=='all'||layer=='geofences'||layer=='outline') {
          const types = layer == 'all' ? ['geofences', 'outline'] : [layer];
          _.each(types, type => {
            if (!_.isNil(this.map[type].ref)) {
              _.each(this.map[type].features, feature => {
                feature.forEach(f => this.map[type].ref.remove(f));
              });
              _.each(this.map[type].events, e => {
                this.google.maps.event.removeListener(e);
              });
              if (style!==false) {
                console.log('clear style');
                this.map[type].ref.setStyle({});
              }
            }
          })
        }
        if (layer=='all'||layer=='impressions') {
          const controller = this.map.impressions;
          controller.selected = null;
          controller.hours = [];
          controller.ready = false;
          controller.playing = false;
          if (controller.timer!=null) clearTimeout(controller.timer);
          controller.timer = null;
        }
        const all = layer=='all' ? ['geofences', 'outline', 'pois', 'impressions'] : [layer];
        _.each(all, l => {
          if (_.has(this.map[l], 'data')&&data!==false) this.map[l].data = {};
          if (_.has(this.map[l], 'features')) this.map[l].features = {};
          if (_.has(this.map[l], 'loader')) this.map[l].loader.pending = {};
          // this.map[l].bounded = false;
        })
        this.map.info.toggle = false;
        this.map.info.selected = null;
      },
      
      poiMouseOver (point, event) {
        point.drawing.setOptions({ 
          fillOpacity: _.isNil(this.metric) ? .48 : (point.opacity[this.metric] * .32) + .02,
          strokeWeight: 2,
          strokeOpacity: .64,
        });
        this.map.info.title = point.title;
        this.map.info.metrics = _.isNil(this.metric) ? null : _.mapValues(this.map.info.metrics, (m, k) => {
          m.value = point[k];
          return m;
        });
        this.map.info.toggle = true;
      },

      poiMouseOut (point, event) {
        _.each(this.map.pois.points, p => {
          p.drawing.setOptions({ 
            fillOpacity: .24,
            strokeWeight: 1,
          });
        })
        point.drawing.setOptions({ 
          fillOpacity: .24,
          strokeWeight: 2,
        });
        this.map.info.toggle = false;
      },

      cityMouseOver (city, event) {
        this.map.info.title = city.title;
        this.map.info.city = null;
        this.map.info.toggle = true;
      },
      cityMouseOut (city, event) {
        this.map.info.toggle = false;
      },
      selectCity (id) {
        this.$emit('select-city', id)
      },

      setPointStyle (value, metric, style) {
        const min = this.map.legend[metric].min;
        const max = this.map.legend[metric].max;
        const low = [178,56,50];   // color of mag 1.0
        const high = [354,100,72];  // color of mag 6.0 and above

        // fraction represents where the value sits between the min and max
        const fraction = (value / max);
        const color = this.interpolateHsl(low, high, fraction);
        // console.log(color, fraction);
        return {
          fillColor: style.fillColor,
          fillOpacity: (fraction * .48) + .32,
          strokeColor: style.strokeColor,
          strokeOpacity: style.strokeOpacity,
          strokeWeight: style.strokeWeight
        }
      },

      initOutlineLayer () {
        console.log('initOutlineLayer...');
        const $ = this;
        const data = this.outline.data;
        const selectable = _.has(this.outline, 'selectable') && this.outline.selectable;
        const hoverable = _.has(this.outline, 'hoverable') && this.outline.hoverable;
        console.log('hoverable', hoverable)
        return this.$refs.map.$mapPromise.then((map) => {
          if ($.map.ref==null) $.map.ref = map;
          if ($.map.outline.ref==null) {
            $.map.outline.ref = new $.google.maps.Data({ map });
          }
          $.map.outline.ref.setStyle($.setOutlineStyle);
          
          const geofences = _.keyBy(data, 'id');
          $.map.outline.data = _.clone(geofences);
          const features = $.map.outline.features;
          const updated = !_.isEqual(_.keys(geofences).sort(), _.keys(features).sort());
          if (!updated) {
            if (_.has(this.outline, 'zoom')&&!_.isEmpty($.outline.zoom)) this.mapBounds('outline', this.outline.zoom);
            return;
          }
          $.clearLayer('outline', false, false);

          $.google.maps.event.removeListener($.map.outline.events.click);
          if (selectable) {
            $.map.outline.events.click = $.map.outline.ref.addListener("click", (event) => {
              const id = event.feature.getProperty('id');
              const selected = $.map.outline.data[id]['selected'];
              console.log('select', id, !selected);
              $.selectGeofence('outline', id, !selected);
            });
          }
          $.google.maps.event.removeListener($.map.outline.events.over);
          $.google.maps.event.removeListener($.map.outline.events.out);
          if (hoverable) {
            $.map.outline.events.over = $.map.outline.ref.addListener("mouseover", (event) => {
              $.map.outline.ref.revertStyle();
              const id = event.feature.getProperty('id');
              const selected = selectable&&$.map.outline.data[id]['selected'];
              $.map.outline.ref.overrideStyle(event.feature, { 
                strokeWeight: selected ? 2 : 2,
                fillOpacity: hoverable ? selected ? .4 : .32 : 0,
                strokeColor: '#698DF2',
                strokeOpacity: .64,
              });
              $.map.info.title = $.map.outline.data[id]['title'];
              const city = $.map.outline.data[id]['city']
              $.map.info.city = _.isNil(city) ? null : city['title'];
              $.map.info.toggle = true;
            });
            $.map.outline.events.out = $.map.outline.ref.addListener("mouseout", (event) => {
              $.map.outline.ref.revertStyle();
              $.map.info.toggle = false;
            });
          }

          const source = _.cloneDeep($.map.outline.source);
          let pending = []
          // console.log(geofences, source);

          _.each(geofences, (g) => {
            const k = _.find(source, (s, id) => {
              return id == g.id;
            })
            if (!_.isNil(k)) {
              let geojson = _.has(k, 'geojson') ? _.clone(k).geojson : k;
              geojson = _.isString(geojson) ? JSON.parse(geojson) : geojson;
              geojson.features[0].properties = {
                id: g.id,
                selected: !selectable || _.has(g, 'selected') && g.selected,
                title: g.title,
                city: _.has(g, 'city')&&_.has(g.city, 'title') ? g.city.title : null,
              }
              const feature = $.map.outline.ref.addGeoJson(geojson, { id: g.id });
              $.map.outline.features[g.id] = feature;
            }else{
              if (_.has(g, 'url')) {
                $.geojsonLoader('outline', $.map.outline.ref, g, false);
                pending.push(g);
              }
            }
          });

          if (!_.isEmpty(pending)) {
            $.map.outline.loader.pending = _.keyBy(pending, 'id');
          }

          console.log('get outline bounds');
          $.mapBounds('outline');
        });
      },

      setOutlineStyle (feature) {
        const id = feature.getProperty('id');
        const selected = !_.has(this.map.outline.data[id], 'selected') || this.map.outline.data[id]['selected'];
        const hoverable = _.has(this.outline, 'hoverable') && this.outline.hoverable;
        return _.has(this.outline, 'style') ? this.outline.style : {
          fillColor: '#698DF2',
          fillOpacity: hoverable ? selected ? .48 : .16 : 0.08,
          strokeColor: hoverable ? '#FFFFFF' : '#698DF2',
          strokeOpacity: hoverable ? .24 : .24,
          strokeWeight: selected ? 2 : 2,
          clickable: hoverable,
          zIndex: parseInt(id)
        };
      },

      selectGeofence (layer, id, select) {
        if (this.disabled) return;
        this.map[layer].data[id].selected = select;
        const zone = _.has(this.map[layer].data[id], 'geofences');
        const geofences = zone ? 
          _.reduce(_.pickBy(this.map[layer].data, g => g.selected), (selected, zone) => {
            return _.concat(selected, _.map(zone.geofences, g => g.id))
          }, []) :
          _.map(_.pickBy(this.map[layer].data, g => g.selected), g => g.id);
        console.log(geofences);
        this.$emit('update', { prop: 'geofences', value: geofences });
      },

      initGeofenceLayer () {
        console.log('initGeofenceLayer...');

        const $ = this;
        const data = this.geofences.data;
        const type = this.geofences.type;
        const metric = this.metric;
        if (_.some(data, (d) => _.has(d, metric))) {
          const s = _.toArray(data);
          this.map.legend.min = _.minBy(s, metric)[metric];
          this.map.legend.max = _.maxBy(s, metric)[metric];
          // if (metric=='cpm') {
          //   const min = this.map.legend.max;
          //   const max = this.map.legend.min;
          //   this.map.legend.min = min;
          //   this.map.legend.max = max;
          // }
        }
        return this.$refs.map.$mapPromise.then(async (map) => {
          if ($.map.ref==null) $.map.ref = map;
          if ($.map.geofences.ref==null) {
            $.map.geofences.ref = new $.google.maps.Data({ map });
          }
          $.map.geofences.ref.setStyle($.setGeofenceStyle);

          const geofences = $.geofences.data;
          $.map.geofences.data = _.clone(geofences);
          const features = $.map.geofences.features;
          const updated = _.difference(_.keys(geofences), _.keys(features)).length>0||type!=$.map.geofences.type;
          if (!updated) {
            if (_.has($.geofences, 'zoom')&&!_.isEmpty($.geofences.zoom)) $.mapBounds('geofences', $.geofences.zoom);
            let legend =  $.map.legend;
            legend.steps = _.concat([legend.min], _.map([...Array(legend.range-2).keys()], (r) => {
              return _.round((legend.max / (legend.range+2)) * (r+1));
            }), [legend.max]);
            return;
          }

          $.google.maps.event.removeListener($.map.geofences.events.over);
          $.map.geofences.events.over = $.map.geofences.ref.addListener("mouseover", (event) => {
            const id = event.feature.getProperty('id');
            // console.log(id);
            $.map.geofences.ref.revertStyle();

            $.map.geofences.features[id].forEach(f => {
              $.map.geofences.ref.overrideStyle(f, { 
                strokeWeight: 2,
                // fillOpacity: .4,
                strokeOpacity: .36,
              });
            });
            const data = $.map.geofences.data[id];
            $.map.info.title = data['title'];
            $.map.info.city = _.has(data, 'city')&&_.has(data.city, 'title') ? data['city']['title'] : null;
            $.map.info.metrics.audience.value = data['audience'];
            $.map.info.metrics.impressions.value = data['impressions'];
            $.map.info.metrics.spent.value = data['spent'];
            $.map.info.metrics.cpm.value = data['cpm'];
            $.map.info.metrics.airtime.value = data['airtime'];
            $.map.info.toggle = true;
          });

          $.google.maps.event.removeListener($.map.geofences.events.out);
          $.map.geofences.events.out = $.map.geofences.ref.addListener("mouseout", (event) => {
            $.map.geofences.ref.revertStyle();
            $.map.info.toggle = false;
          });

          const source = _.cloneDeep($.map[type=='cities' ? type : 'geofences'].source);
          $.map.geofences.type = type;
          let pending = [];
          // console.log(geofences, source);

          $.map.geofences.ref.forEach(function(feature) {
            const id = feature.getProperty('id');
            if (!_.has(geofences, id)) {
              // console.log('removing', id);
              $.$delete($.map.geofences.features, id);
              $.map.geofences.ref.remove(feature);
            }
          });

          let i=0;
          for (const k in geofences) {
            const g = geofences[k];
            if (!_.isNil(g)&&_.has(source, k)) {
              i+=1;
              let geojson = _.has(source[k], 'geojson') ? _.clone(source[k]).geojson : source[k];
              geojson = _.isString(geojson) ? JSON.parse(geojson) : geojson;

              $.addGeojson('geofences', geojson, k, g, $.map.geofences.ref);
              // setTimeout($.addGeojson, 16*i, 'geofences', geojson, k, g, map.data);
            }else{
              if (_.has(g, 'url')) {
                this.geojsonLoader('geofences', $.map.geofences.ref, g, false);
                pending.push(g);
              }
            }
          }

          if (!_.isEmpty(pending)) {
            $.map.geofences.loader.pending = _.keyBy(pending, 'id');
          }else{
            console.log('get geofences bounds');
            $.mapBounds('geofences');
          }

          let legend =  $.map.legend;
          legend.steps = _.concat([legend.min], _.map([...Array(legend.range-2).keys()], (r) => {
            return _.round((legend.max / (legend.range+2)) * (r+1));
          }), [legend.max]);

        });
      },

      geojsonLoader (layer, map, g, next) {
        if (_.isNil(map)) map = this.map[layer].ref;
        if (_.isNil(g)) {
          if (_.isEmpty(this.map[layer].loader.pending)) {
            return;
          }else{
            g = _.sample(this.map[layer].loader.pending);
          }
        }
        this.map[layer].loader.loading = true;
        if (_.isNil(next)) next = true;
        fetch(g.url, { cache: "no-store" }).then(r => r.json())
        .then((json) => {
          const geojson = this.formatGeojson(_.has(json, 'geojson') ? json.geojson : json);
          this.addGeojson(layer, geojson, g.id, g, map);

          this.map[layer].loader.loading = false;
          if (_.has(this.map[layer].loader.pending, g.id)) this.$delete(this.map[layer].loader.pending, g.id);
          if (next) this.geojsonLoader(layer, map);
        }, (error) => {
          console.log(g.id, g, error);
          if (!_.isNil(_.find(this.map[layer].data, ['id', g.id]))) {
            setTimeout(($, layer, map, g, next) => {
              $.geojsonLoader(layer, map, g, next);
            }, 5000, this, layer, map, g, next);
          }else{
            if (_.has(this.map[layer].loader.pending, g.id)) this.$delete(this.map[layer].loader.pending, g.id);
          }
        });
      },

      addGeojson (layer, geojson, k, g, map) {
        // console.log(k);
        // const hasCity = (_.has(g, 'city')&&!_.isNil(g.city));
        let feature = null;
        if (_.has(this.map[layer].features, k)) { 
          feature = this.map[layer].features[k];
        }else{
          if (layer=='outline') {
            const selectable = _.has(this.outline, 'selectable') && this.outline.selectable;
            geojson.features[0].properties = {
              id: g.id,
              selected: !selectable || _.has(g, 'selected') && g.selected,
              title: g.title,
              city: _.has(g, 'city')&&_.has(g.city, 'title') ? g.city.title : null,
            }
          }
          if (_.has(this.map[layer].data, g.id)) {
            feature = map.addGeoJson(geojson)
          }else{
            return null;
          }
          // feature = feature[0];
          this.$set(this.map[layer].features, k, feature);
          if (!_.has(this.map[layer].source, k)) this.$set(this.map[layer].source, k, geojson);
        }
        feature.forEach((f) => f.setProperty('id', k));
        // console.log(p[0].getProperty('title'));
      },

      formatGeojson (geojson) {
        if (_.isString(geojson)) geojson = JSON.parse(geojson);
        if (geojson.type!='Feature'&&geojson.type!='FeatureCollection') {
          geojson = { type: 'Feature', geometry: geojson }
        }
        return geojson;
      },

      setGeofenceStyle (feature) {
        const style = _.has(this.geofences, 'style') ? this.geofences.style : {
          fillColor: '#698DF2',
          strokeColor: '#698DF2',
          strokeOpacity: .16,
          strokeWeight: 1,
        };

        const min = this.map.legend.min;
        const max = this.map.legend.max;
        const id = feature.getProperty('id');
        let fraction = 0;
        if (_.has(this.map.geofences.data, id)) {
          const metric = this.map.geofences.data[id][this.metric];
          const low = [224,80,64];   // color of mag 1.0
          const high = [14,100,72];  // color of mag 6.0 and above
          fraction = (metric / max);
          // const color = this.interpolateHsl(low, high, fraction);
          // console.log(color, fraction);
        }
        return {
          fillColor: style.fillColor, //color
          fillOpacity: (fraction * .48) + .32,
          strokeColor: style.strokeColor,
          strokeOpacity: style.strokeOpacity,
          strokeWeight: style.strokeWeight
        }
      },

      positionInfoCard (e) {
        const card = this.map.info;
        if (card.toggle&&card.selected==null) {
          const spacing = { top: -24, left: 48 };
          const margin = 4;
          const map = this.$refs.container.getBoundingClientRect();
          const info = this.$refs.info.getBoundingClientRect();
          let top = e.clientY + spacing.top;
          let left = e.clientX + spacing.left;
          const bottom = info.height + top;
          const right = info.width + left;

          if (bottom>=map.height+map.top-margin) {
            top -= bottom - (map.height + map.top) + margin;
          }else if (top<=margin+map.top) {
            top = map.top + margin;
          }
          if (right>=map.width+map.left-margin) {
            left -= (2 * spacing.left) + info.width;
          }
          // console.log(top, map.top, info.height);
          card.top = top;
          card.left = left;
        }
      },

      calcLegendIntensity (v) {
        const max = this.map.legend.max;
        // const low = [224,80,64];   // color of mag 1.0
        // const high = [24,100,72];  // color of mag 6.0 and above

        // // fraction represents where the value sits between the min and max
        // const fraction = (v / max);

        // const color = this.interpolateHsl(low, high, fraction);
        const i = v != 0 ? (v/max) : 0;
        return {
          'background-color': i != 0 ? 'rgba(71, 108, 255, ' + ((i * .48) + .32).toString() + ')' : 'rgba(0,0,0,.04)'//color
        };
      },

      initHeatmap () {
        return this.$refs.map.$mapPromise.then((map) => {
          this.map.heatmap.loaded = true;
          this.getBounds();
          return new this.google.maps.visualization.HeatmapLayer({
            data: new this.google.maps.MVCArray(this.heatmapPoints()),
            map: map,
            opacity: .5,
            // maxIntensity: this.heatmapMax,
            radius: this.map.radius,
          });
        });
      },

      updateHeatmap () {
        this.map.heatmap.ref.then((layer) => {
          layer.set('data', new this.google.maps.MVCArray(this.heatmapPoints()));
          // layer.set('maxIntensity', this.heatmapMax);
          console.log(layer.get('maxIntensity'));
        });
      },

      heatmapPoints () {
        // const filter = this.map.filter;
        // console.log(this.heatmap.data[_.find(filter.steps, { doy: filter.interval }).date]);
        return _.map(this.heatmap.data, (point) => {
            return { location: new this.google.maps.LatLng(point.lat, point.lng), weight: point.audience };
          });
      },

      statusLight (buzzer) {
        let color = 'grey';
        if (this.buzzers.alert) {
          const updated = buzzer.updated;
          const tracked = buzzer.impression.updated;
          let diff;
          if (buzzer.status=='ON') {
            if (updated&&tracked) {
              const diffTrack = moment(updated).diff(tracked,'minutes');
              const diffImpression = moment().diff(updated,'minutes'); 
              diff = diffImpression;
              if (diff<=15) {
                color = 'success';
              }else{
                color = 'warning';
              }
            }else{
              color = 'grey';
            }
          }else{
            color = 'error';
          }
        }else{
          color = 'primary';
        }
        return color;
      },

      setBuzzerIcon (buzzer) {
        const url = this.map.icons.buzzer.url.split('.');
        const selected = buzzer.code == this.buzzers.selected;
        const sufix = selected ? 'selected' : this.buzzers.alert ? this.statusLight(buzzer) : 'default';
        const icon = {
          url: url[0] + '-' + sufix + '.' + url[1],
          size: this.map.icons.buzzer.size,
          anchor: this.map.icons.buzzer.anchor,
        };
        return icon;
      },
      
      // highlightBuzzer (buzzer) {
      //   // TODO: Refactor w/o iteration
      //   _.forEach(this.buzzers.data, (item,key) => {
      //     if (item.selected) {
      //       this.buzzers.data[key].selected = false;
      //     }
      //   });
      //   if (buzzer) this.buzzers.data[buzzer].selected = this.buzzers.data[buzzer].loading = true;
      // },

      selectBuzzer (buzzer) {
        if (!this.auditor) {
          this.view.panned = false;
          this.$router.push({
            path: `/buzzers/${buzzer}`
          });
        }
      },

      getBounds () {
        if (this.map.ready) {
          let bounds = new this.google.maps.LatLngBounds();
          // get bound points
          if (this.buzzers.toggle) {
            _.map(this.trackedBuzzers, (buzzer) => {
              const position = buzzer.impression.position;
              bounds.extend(new this.google.maps.LatLng(position.lat, position.lng));
            });
          }
          if (this.impressions.toggle) {
            if (_.size(this.map.cities.source)>0) {
              _.each(this.map.cities.source, (city) => {
                if (_.has(city, 'bounds')) {
                  const ne = new this.google.maps.LatLng(city.bounds.getNorthEast());
                  bounds.extend(ne);
                  const sw = new this.google.maps.LatLng(city.bounds.getSouthWest());
                  bounds.extend(sw);
                }
              })
            }else{
              _.each(this.impressions.data, (hour) => {
                _.each(hour, (impression) => {
                  const position = impression.position;
                  bounds.extend(new this.google.maps.LatLng(position.lat, position.lng));
                });
              });
            }
          }
          if (this.heatmap.toggle) {
            _.map(this.heatmapPoints(), (point) => {
              bounds.extend(point.location);
            });
          }
          if (!this.view.panned) {
            if (this.buzzers.selected) {
              const position = this.buzzers.data[this.buzzers.selected].impression.position;
              if (position) this.mapCenter(position);
            }else{
              this.mapBounds(null, null, bounds);
            }
            this.view.panned = false;
          }
        }
      },

      centerBuzzer(position) {
        if (position.lat!==0&&position.lat!==null) this.mapCenter(position, 15);
      },

      mapCenter (position, zoom) {
        if (typeof zoom == 'undefined') zoom = 15;
        this.$refs.map.$mapPromise.then((map) => {
          map.setZoom(zoom);
          map.panTo(position);
        });
      },

      mapBounds (layer, features, bounds, callback) {
        const $ = this;
        bounds = _.isNil(bounds) ? new google.maps.LatLngBounds() : bounds;
        this.$refs.map.$mapPromise.then((map) => {
          // map.panToBounds(bounds, this.mapPadding);
          if (layer=='geofences'&&$.geofences.toggle&&_.size($.geofences.data)>0) { 
            if (_.isNil(features)||_.isEmpty(features)) {
              $.map[layer].ref.forEach(function(feature){
                feature.getGeometry().forEachLatLng(function(latlng){
                  bounds.extend(latlng);
                });
              });
            }else{
              _.each(features, f => {
                if (_.has($.map[layer].features, f)) {
                  const feature = $.map[layer].features[f];
                  feature.forEach(f => {
                    f.getGeometry().forEachLatLng(function(latlng){
                      bounds.extend(latlng);
                    });
                    $.map[layer].ref.overrideStyle(f, {
                      fillOpacity: .8,
                      strokeWeight: 2,
                      strokeOpacity: .8,
                    });
                  });
                }
              });
              setTimeout(($, map, layer) => {
                map.revertStyle();
                $.$emit('zoom-end', layer);
              }, 1500, $, $.map[layer].ref, layer);
            }
          }else if (layer=='outline'&&$.outline.toggle&&_.size($.outline.data)>0) {
            if (_.isNil(features)||_.isEmpty(features)) {
              const selectable = _.has($.outline, 'selectable') && $.outline.selectable;
              const available = !_.some($.map[layer].data, ['selected', true]);
              _.each($.map[layer].data, g => {
                if (_.has($.map[layer].features, g.id)) {
                  if (available||!selectable||(_.has(g, 'selected')&&g.selected)) {
                    $.map[layer].features[g.id].forEach(f => {
                      f.getGeometry().forEachLatLng(function(latlng){
                        bounds.extend(latlng);
                      });
                    });
                  }
                }
              });
            }else{
              _.each(features, f => {
                if (_.has($.map[layer].features, f)) {
                  const feature = $.map[layer].features[f];
                  feature.forEach(f => {
                    f.getGeometry().forEachLatLng(function(latlng){
                      bounds.extend(latlng);
                    });
                    $.map[layer].ref.overrideStyle(f, { 
                      fillOpacity: .8,
                      strokeWeight: 2,
                      strokeOpacity: .8,
                    });
                  });
                }
              });
              setTimeout(($, map, layer) => {
                map.revertStyle();
                $.$emit('zoom-end', layer);
              }, 1500, $, $.map[layer].ref, layer);
            }
          }else if (layer=='pois'&&$.pois.toggle&&(!_.isNil(features)||_.size($.pois.data)>0)) {
            if (_.isNil(features)||_.isEmpty(features)) {
              // bound POIs
              _.each($.map.pois.points, p => {
                bounds.extend(p.position);
                const radius = p.drawing.getBounds();
                bounds.extend(radius.getNorthEast());
                bounds.extend(radius.getSouthWest());
              });
              // bound Polygons
              $.map[layer].ref.forEach(function(feature){
                feature.getGeometry().forEachLatLng(function(latlng){
                  bounds.extend(latlng);
                });
              });
            }else{
              // bound POIs
              _.each(features, i => {
                if (_.has($.map[layer].points, i)) {
                  const p = $.map.pois.points[i];
                  console.log('point bounds', p.position);
                  bounds.extend(p.position);
                  const radius = p.drawing.getBounds();
                  bounds.extend(radius.getNorthEast());
                  bounds.extend(radius.getSouthWest());
                }
              });
              // bound Polygons
              _.each(features, f => {
                if (_.has($.map[layer].features, f)) {
                  const feature = $.map[layer].features[f];
                  feature.forEach(f => {
                    f.getGeometry().forEachLatLng(function(latlng){
                      bounds.extend(latlng);
                    });
                  });
                  $.map[layer].ref.overrideStyle(feature, { 
                    strokeWeight: 2,
                    strokeOpacity: .8,
                  });
                }
              });
              setTimeout(($, map, layer) => {
                if (!_.isNil($.map[layer].ref)) map.revertStyle();
                $.$emit('zoom-end', layer);
              }, 1500, $, $.map[layer].ref, layer);
            }
          }
          if (!_.isNil(bounds)&&!bounds.isEmpty()) {
            this.map[layer].bounded = true;
            map.fitBounds(bounds, $.map.options.padding);
          }
          if (!_.isNil(callback)) callback();
        });
      },

      // processGeojson (geometry, callback, thisArg) {
      //   if (geometry instanceof google.maps.LatLng) {
      //     callback.call(thisArg, geometry);
      //   } else if (geometry instanceof google.maps.Data.Point) {
      //     callback.call(thisArg, geometry.get());
      //   } else {
      //     geometry.getArray().forEach(function(g) {
      //       processPoints(g, callback, thisArg);
      //     });
      //   }
      // },


      filterInterval (data) {
        let steps = _.map(_.keys(data), (key) => {
          return {
            doy: moment(key, 'DD/MM/YY').dayOfYear(),
            date: key
          }
        }).sort();
        this.map.filter.steps = steps;
        this.map.filter.label = steps[steps.length-1].date;
        this.map.filter.max = steps[steps.length-1].doy;
        this.map.filter.min = steps[0].doy;
        this.map.filter.interval = this.map.filter.max;
      },

      matchKey (k, item) {
        const key = new RegExp(k, 'gi');
        return key.test(item.code) || key.test(item.mode) || key.test(item.status) || key.test(item.driver.name);
      },

      mapAvailable () {
        console.log('map NOT ready...');
        if (_.has(this.$refs, 'map')) {
          console.log('map ready', this.map.ref);
          this.map.ready = true;
          if (this.geofences.toggle&&_.size(this.geofences.data)>0) {
            this.initGeofenceLayer();
          }else if (this.pois.toggle&&_.size(this.pois.data)>0) {
            this.initPoiLayer();
          }else if (this.outline.toggle&&_.size(this.outline.data)>0) {
            this.initOutlineLayer();
          }else if (this.impressions.toggle&&_.size(this.impressions.data)>0) {
            this.getBounds();
          }
          if (_.size(this.cities)>0) {
            this.loadCities();
          }
        }else{
          setTimeout(this.mapAvailable, 500);
        }
      }

    },

    async mounted () {
      // this.map.ref = this.$refs.map.$mapObject;
      await this.$gmapApiPromiseLazy().then(() => {
        this.mapAvailable();
      });
      this.view.panned = false;
    },

    beforeDestroy () {
      this.clearLayer();
    },

    components: {
      MTabs: () => import('@/components/mTabs'),
      DrawingToolbar: () => import('@/components/mDrawingToolbar'),
      MediaPlayer: () => import('@/components/campaigns/MediaPlayer'),
    }

  }

</script>
